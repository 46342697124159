import { ref } from 'vue'
import { UUID } from '@avvoka/shared'
import type { V } from '@component-utils/types'
import { useErrorToast } from '@component-utils/toasts'

export const getUUID = () => {
  /**
   * Lazy fix for crypto#randomUUID not available for localhost when aliased
   */
  if (import.meta.env.PROD) {
    return window.crypto.randomUUID()
  } else {
    return UUID.new()
  }
}

export function useElementId(id: string | undefined) {
  return ref(id || `el-${getUUID()}`)
}

export function useDescriptionElementId(description: string | undefined, id: string) {
  return description ? `description-${id}` : undefined
}

export function getItemValue(item: V.ContextMenu.Checkbox) {
  if (typeof item.value === 'boolean') return item.value
  else return item.value.get()
}

export function addUniqueIds <T, K extends string = 'id'>(array: T[], property: K = 'id' as K): Array<T & Record<K, string>> {
  return array.map((item) => ({ ...item, [property]: getUUID() } as T & Record<K, string>))
}

export function useSubmit<T extends unknown[]>(onSubmit: (...args: T) => Promise<void>, onError = () => {}) {
  const isSubmitting = ref(false)

  const submit = async (...args: T) => {
    if (isSubmitting.value) return

    isSubmitting.value = true

    try {
      await onSubmit(...args)
    } catch (e) {
      useErrorToast(e)

      onError()
    } finally {
      isSubmitting.value = false
    }
  }

  return {
    submit,
    isSubmitting
  }
}

export function numberToHumanSize(bytes: number) {
  if (bytes === null || isNaN(bytes)) return null
  if (bytes === 0) return '0 Bytes'
  if (bytes < 0) return null

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB']
  const exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(1024)), units.length - 1)
  const value = (bytes / Math.pow(1024, exponent)).toFixed(2).replace(/\.?0+$/, '')

  return `${value} ${units[exponent]}`
}
